import * as React from 'react';
import {
  Dropdown,
  ButtonType,
  ArrowDownIcon,
  variants,
  SnapInIcon,
  SnapInTypeDto,
  getSnapInIcon,
  getProfileNameBySymbol,
  SnapInsGroupsTranslations,
  ProfilesEnums,
  SnapInsGroupType,
  SnapInsGroupsEnums,
  Input,
  InputStyleTypeEnum,
} from '@streda/web_components';
import { ThemeContext } from 'styled-components';
import {
  DropdownWrapper,
  Placeholder,
  PlaceholderIcon,
  ProfileBtnsContainer,
  ProfileInToggle,
  SectionContainer,
  SnapInsGroupLabel,
  SnapInsOption,
  StyledButton,
  StyledSnapInName,
  StyledToggle,
  ToggleContainer,
} from './dock-dropdown.styled';
import { IContentElement, IDockDropdown, IToggleElement } from './dock-dropdown.types';
import { DockCableTypeEnum } from '../cable-type-dropdown/cable-type-dropdown.types';

const cleanDockName = input => input.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, ' ').slice(0, 50);

function SnapIn({ dock, snapInTypes, onDockChange, compatibilityWarning }) {
  const { dockCode, name } = dock;
  const SnapInIcon = getSnapInIcon(dockCode);
  const snapIn = snapInTypes.find(snapInType => snapInType.symbol === dockCode) || {};
  const [localSnapInName, setLocalSnapInName] = React.useState(name || snapIn.name);

  React.useEffect(() => {
    if (name) {
      setLocalSnapInName(name);
    }
  }, [name]);

  return (
    <StyledToggle>
      <SnapInIcon height={20} width={null} />
      <StyledSnapInName>
        <Input
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
          styleType={InputStyleTypeEnum.INLINE}
          placeholder="Snap in name"
          value={localSnapInName}
          onBlur={() =>
            onDockChange({
              ...dock,
              name: localSnapInName,
            })
          }
          onChange={newName => setLocalSnapInName(newName)}
        />
        <ProfileInToggle compatibilityWarning={compatibilityWarning}>
          {getProfileNameBySymbol(dockCode.charAt(0) as ProfilesEnums)}
        </ProfileInToggle>
      </StyledSnapInName>
    </StyledToggle>
  );
}
const Toggle: React.FC<IToggleElement> = ({
  dock,
  snapInTypes,
  inlineMode,
  compatibilityWarning,
  onDockChange,
}) => {
  const themeContext = React.useContext(ThemeContext);
  const { dockCode } = dock;

  return (
    <ToggleContainer inlineMode={inlineMode}>
      {dockCode ? (
        <SnapIn
          dock={dock}
          onDockChange={onDockChange}
          snapInTypes={snapInTypes}
          compatibilityWarning={compatibilityWarning}
        />
      ) : (
        <Placeholder>
          <PlaceholderIcon fill={`${themeContext.colors.font1}60`} />
          Choose the snap-in type & profile
        </Placeholder>
      )}
      <ArrowDownIcon width={24} height={24} />
    </ToggleContainer>
  );
};

const Content: React.FC<IContentElement> = ({
  snapInTypes,
  onSnapInSelect,
  setShowDropdown,
  profile,
  setProfile,
}) => {
  const getFilteredDocks = (groupKey: SnapInsGroupType): SnapInTypeDto[] => {
    return snapInTypes
      .filter(snapInType => snapInType.snapInCodeDockTypeSymbolValue === groupKey)
      .filter(snapInType => {
        const snapInProfile = snapInType.symbol.charAt(0);

        return snapInProfile === profile;
      });
  };

  return (
    <div>
      <SectionContainer>
        <p>Choose profile:</p>
        <ProfileBtnsContainer>
          {Object.values(ProfilesEnums).map(profileKey => (
            <StyledButton
              key={profileKey}
              text={getProfileNameBySymbol(profileKey)}
              type={ButtonType.BUTTON}
              colorType={profileKey === profile ? variants.PRIMARY : variants.WHITE}
              onClick={() => setProfile(profileKey)}
              rounded
            />
          ))}
        </ProfileBtnsContainer>
      </SectionContainer>
      <hr />
      <div>
        {Object.keys(SnapInsGroupsEnums).map(groupKey => {
          const docks = getFilteredDocks(groupKey);

          if (docks.length === 0) {
            return null;
          }

          return (
            <div>
              <SnapInsGroupLabel>{SnapInsGroupsTranslations[groupKey]}</SnapInsGroupLabel>
              {docks.map(dock => {
                const SnapInIcon = getSnapInIcon(dock.symbol);
                return (
                  <SnapInsOption
                    onClick={() => {
                      // @ts-ignore
                      onSnapInSelect({ dockCode: dock.symbol, name: cleanDockName(dock.name) });
                      setShowDropdown(false);
                    }}
                    key={dock.symbol}
                    type={ButtonType.BUTTON}
                  >
                    <SnapInIcon height="32px" width={null} />
                    <div>{dock.name}</div>
                  </SnapInsOption>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const DockDropdown: React.FC<IDockDropdown> = ({
  dock,
  snapInTypes,
  onSnapInSelect,
  inlineMode,
  compatibilityWarning,
  disabled,
}) => {
  const { dockCode } = dock;
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [profile, setProfile] = React.useState(ProfilesEnums.ultimate);

  React.useEffect(() => {
    if (!dockCode) {
      return;
    }

    setProfile(dockCode.charAt(0) as ProfilesEnums);
  }, [dockCode]);

  return (
    <DropdownWrapper>
      <Dropdown
        disabled={disabled}
        onToggle={() => setShowDropdown(!showDropdown)}
        show={showDropdown}
        toggleElement={
          <Toggle
            inlineMode={inlineMode}
            dock={dock}
            snapInTypes={snapInTypes}
            compatibilityWarning={compatibilityWarning}
            onDockChange={onSnapInSelect}
          />
        }
        contentElement={
          <Content
            profile={profile}
            setProfile={setProfile}
            snapInTypes={snapInTypes}
            onSnapInSelect={onSnapInSelect}
            setShowDropdown={setShowDropdown}
          />
        }
      />
    </DropdownWrapper>
  );
};

export default DockDropdown;
