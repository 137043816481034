import { StatusIndicatorInfoLvlEnum } from './status-indicator.types';

const getClassNameForStatus = (infoLvl: StatusIndicatorInfoLvlEnum): string => {
  const baseClassName = 'control-center-text text-label-bold radius-4 h-32 w-64';
  switch (infoLvl) {
    case StatusIndicatorInfoLvlEnum.green:
      return `${baseClassName} color-status-active`;
    case StatusIndicatorInfoLvlEnum.red:
      return `${baseClassName} color-status-inactive`;
    case StatusIndicatorInfoLvlEnum.fulfilledRed:
      return `${baseClassName} b-color-status-inactive`;
    case StatusIndicatorInfoLvlEnum.gray:
      return `${baseClassName} color-status-unknown`;
    default:
      return baseClassName;
  }
};

export default getClassNameForStatus;
