import * as React from 'react';
import {
  AccountRoles,
  hasUserRole,
  HomeIcon,
  IModalState,
  InvitationDto,
  IRemoteTableColumn,
  IRowOption,
  ISelectOption,
  RowOptions,
  SortDirectionEnums,
} from '@streda/web_components';
import IBFile from '../../../../models/file';
import { ILocationEdit } from '../../../../models/location';
import StatusIndicator from '../../../shared/status-indicator/status-indicator';
import { formatDateOnly } from '../../../../utils/date-formater';
import { getNetworkStatus, ScbStatusesEnum } from './location-edit-modal.logic.bcb';
import { StatusIndicatorInfoLvlEnum } from '../../../shared/status-indicator/status-indicator.types';

export interface IGetSnapInsParams {
  criteriaSerialNumber?: string;
  criteriaSnapInCode?: string;
  criteriaLocationId?: string;
  criteriaMacAddress?: string;
  criteriaZigbeeId?: string;
  orderBy?: string;
  descending?: boolean;
  pageNumber?: number;
}

export interface IInstaller {
  activeDirectoryId: string;
  name: string;
}

export interface ILocationEditModalState {
  showGeneralStepValidationErrors?: boolean;
  showDockValidationErrors?: boolean;
  locationTypes: ISelectOption[];
  specFiles?: IBFile[];
  data: ILocationEdit;
  systemSettingsAuthorized: boolean;
  invitations?: InvitationDto[];
  company?: string;
  installers?: IInstaller[];
}

const getScbStatusIndicatorInfoLvl = (status: ScbStatusesEnum) => {
  switch (status) {
    case ScbStatusesEnum.AllConnected:
      return StatusIndicatorInfoLvlEnum.green;
    case ScbStatusesEnum.PartiallyConnected:
      return StatusIndicatorInfoLvlEnum.red;
    case ScbStatusesEnum.Error:
    case ScbStatusesEnum.AllDisconnected:
      return StatusIndicatorInfoLvlEnum.fulfilledRed;
    case ScbStatusesEnum.Unknown:
    default:
      return StatusIndicatorInfoLvlEnum.gray;
  }
};

export interface ILocationEditModalProps {
  state: IModalState;
  onHide?(reload?: boolean, err?: any): void;
  onUpdate?(data: ILocationEdit, err?: any): void;
  reloadData?(): void;
}

export interface ILocationRowOption {
  value: string;
  onClick?(row: any): any;
  testId?: string;
}

export const getLocationColumns = (rowOptions: ILocationRowOption[]) => {
  const columns = [
    { key: 'id', name: '', hide: true },
    {
      key: 'icon',
      name: '',
      custom: true,
      customComponent: row => <HomeIcon key={row.id} />,
      disableSort: true,
      headerStyle: { width: '48px' },
      rowStyle: { paddingTop: '16px', paddingBottom: '16px', paddingLeft: '11px' },
    },
    {
      key: 'address',
      name: 'Address',
      custom: true,
      customComponent: row => {
        return (
          <>
            {row.addressStreet} {row.addressHouseNumber}
          </>
        );
      },
    },
    {
      key: 'addressCity',
      sortKey: 'city',
      name: 'City',
    },
    {
      key: 'locationName',
      name: 'Project name',
    },
    {
      key: 'lotNumber',
      name: 'Lot number',
      disableSort: true,
    },
    {
      key: 'networkStatus',
      name: 'SCB',
      disableSort: true,
      custom: true,
      customComponent: row => <StatusIndicator status={getNetworkStatus(row.bcbConnectedStatus)} infoLvl={getScbStatusIndicatorInfoLvl(row.bcbConnectedStatus)} />,
      align: 'center',
      headerStyle: { width: '160px', textAlign: 'center' },
    },
    {
      key: 'insertDate',
      sortKey: 'date',
      name: 'Date',
      rowStyle: { color: '#787876' },
      custom: true,
      customComponent: row => {
        if (row.insertDate) {
          const date = new Date(Date.parse(row.insertDate));
          return <>{formatDateOnly(date)}</>;
        }
        return null;
      },
      headerStyle: { width: '130px' },
      sortDirection: SortDirectionEnums.ASCENDING,
    },
    {
      disableSort: true,
      key: '',
      name: '',
      align: 'right',
      custom: true,
      customComponent: row =>
        RowOptions({
          options: rowOptions.map(rowOption => {
            return {
              ...rowOption,
              onClick: () => rowOption.onClick(row),
              testId: rowOption.testId,
            } as IRowOption;
          }),
        }),
      headerStyle: { width: '40px', marginRight: '16px' },
      rowStyle: { overflow: 'unset' },
    },
  ] as IRemoteTableColumn[];

  if (hasUserRole(AccountRoles.SUPER_ADMINISTRATOR)) {
    columns.splice(4, 0, {
      key: 'companyName',
      name: 'Company',
    });
  }

  return columns;
};
