import * as React from 'react';
import clsx from 'clsx';
import styled, { ThemeContext } from 'styled-components';
import {
  AlertIcon,
  ButtonType,
  DeleteIcon,
  Draggable,
  getElementIndex,
  IconButton,
  Input,
  InputStyleTypeEnum,
  moveElement,
  ProfilesEnums,
} from '@streda/web_components';
import { IDocksListElementProps } from './docks-list-element.types';
import {
  getDockCableType,
  handleOnDelete,
  handleOnSnapInChange,
  handleOnSnapInPositionChange,
} from './docks-list-element.logic';
import CableTypeDropdown from '../cable-type-dropdown/cable-type-dropdown';
import DockDropdown from '../dock-dropdown/dock-dropdown';
import { DockCableTypeEnum } from '../cable-type-dropdown/cable-type-dropdown.types';
import { DockPositionDropdown } from '../add-dock/add-dock';
import DockMountTypeDropdown from '../dock-mount-type-dropdown/dock-mount-type-dropdown';
import { DockMountTypeEnum } from '../dock-mount-type-dropdown/dock-mount-type-dropdown.types';

const StyledAlertIcon = styled(AlertIcon)`
  margin-right: 8px;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const StyledDraggable = styled(Draggable)`
  display: table-row;
  width: 100%;

  & * {
    user-select: none;
  }
`;

const DocksListElement: React.FC<IDocksListElementProps> = (props: IDocksListElementProps) => {
  const {
    className,
    addMode,
    dock,
    onDelete,
    onChange,
    snapInTypes,
    snapInPositions,
    onDockNumberChange,
    pending,
    onDockOrderChange,
    dockIndex,
  } = props;
  const themeContext = React.useContext(ThemeContext);
  const [snapInPosition, setSnapInPosition] = React.useState({ value: '', label: '' });
  const [dockNumber, setDockNumber] = React.useState<string>('');
  const [compatibilityWarning, setCompatibilityWarning] = React.useState<boolean>(true);

  React.useEffect(() => {
    setSnapInPosition(
      snapInPositions.find(o => o.value === dock.positionId) ?? { value: '', label: '' },
    );
  }, [snapInPositions]);

  React.useEffect(() => {
    const dockProfile = dock.dockCode.charAt(0) as ProfilesEnums;
    const isElectricOnlyProfile =
      dockProfile === ProfilesEnums.basic || dockProfile === ProfilesEnums.smart;
    const isDockFunctionLimited =
      (dock.cableType === DockCableTypeEnum.Hybrid && isElectricOnlyProfile) ||
      (dock.cableType === DockCableTypeEnum.Electric && !isElectricOnlyProfile);

    setCompatibilityWarning(isDockFunctionLimited);
    setDockNumber(dock.number);
  }, [dock]);
  const onDragEnd = ({ target }) => {
    const index = parseInt(target.getAttribute('data-index'), 10);
    const startIndex = parseInt(target.getAttribute('data-start-index'), 10);

    if (index === startIndex) {
      return;
    }

    onDockOrderChange(startIndex, index).catch(() => {
      const parent = target.closest('tbody');

      moveElement(parent, index, dockIndex);
    });
  };
  const onMove = event => {
    const { target, clientX, clientY } = event;
    const elementUnderCursor = document.elementFromPoint(clientX, clientY);
    const rowElement = elementUnderCursor && elementUnderCursor.closest('.draggable');
    const parent = target.closest('tbody');

    if (!rowElement || !parent.contains(rowElement)) {
      return;
    }

    const boundingRect = rowElement.getBoundingClientRect();
    const rowMiddle = boundingRect.top + boundingRect.height / 2;
    const newIndex = getElementIndex(rowElement);
    const currentIndex = parseInt(event.target.getAttribute('data-index'), 10);

    if (currentIndex === newIndex) {
      return;
    }

    if (clientY < rowMiddle) {
      moveElement(parent, currentIndex, newIndex);
      event.target.setAttribute('data-index', newIndex);
    } else if (clientY >= rowMiddle) {
      moveElement(parent, currentIndex, newIndex + 1);
      event.target.setAttribute('data-index', newIndex + 1);
    }
  };

  return (
    <StyledDraggable
      disabled={!addMode}
      onDragEnd={onDragEnd}
      onMove={onMove}
      className={className}
      key={`tr-dock-${dock.id}`}
    >
      <td aria-label="Cable type">
        <CableTypeDropdown
          disabled={!addMode}
          cableType={dock.cableType}
          onCableChange={cableType => {
            handleOnSnapInChange(dock, dock.dockCode, cableType, dock.isFlipped, onChange);
          }}
          inlineMode
          compatibilityWarning={compatibilityWarning}
          selectedDock={dock.dockCode}
        />
      </td>
      <td aria-label="Mount type">
        <DockMountTypeDropdown
          disabled={!addMode}
          mountType={dock.isFlipped ? DockMountTypeEnum.Upwards : DockMountTypeEnum.Downwards}
          onChange={mountType => {
            handleOnSnapInChange(
              dock,
              dock.dockCode,
              dock.cableType,
              mountType === DockMountTypeEnum.Upwards,
              onChange,
            );
          }}
          inlineMode
        />
      </td>
      <td className="ellipsis" aria-label="Dock number">
        <Input
          disabled={!addMode}
          value={dockNumber}
          styleType={InputStyleTypeEnum.INLINE}
          onBlur={() => {
            onDockNumberChange(dock.number, dockNumber).catch(() => setDockNumber(dock.number));
          }}
          onChange={setDockNumber}
        />
      </td>
      <td aria-label="Dock">
        <DockDropdown
          snapInTypes={snapInTypes}
          disabled={!addMode}
          dock={dock}
          onSnapInSelect={updatedDock => {
            const { dockCode } = updatedDock;
            handleOnSnapInChange(
              dock,
              updatedDock,
              getDockCableType(dockCode, dock.cableType),
              dock.isFlipped,
              onChange,
            );
          }}
          inlineMode
          compatibilityWarning={compatibilityWarning}
        />
      </td>
      <td aria-label="Position">
        <DockPositionDropdown
          dock={dock}
          value={snapInPosition}
          disabled={!addMode}
          snapInPositions={snapInPositions}
          onPositionChange={value => handleOnSnapInPositionChange(dock, value, onChange, setSnapInPosition)}
        />
      </td>
      <td style={{ textAlign: 'center' }}>
        {addMode && (
          <ActionsContainer>
            {compatibilityWarning && (
              <StyledAlertIcon
                data-tooltip-id="dockCompatibilityWarning"
                fill={themeContext.colors.alert}
              />
            )}
            <IconButton
              icon={<DeleteIcon height={24} />}
              pending={pending}
              type={ButtonType.BUTTON}
              onClick={() => handleOnDelete(onDelete)}
              tooltipId="modalTooltip"
              tooltipText="Delete"
            />
          </ActionsContainer>
        )}
      </td>
    </StyledDraggable>
  );
};

export default React.memo(DocksListElement);
