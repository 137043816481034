import { IAddDockState } from './add-dock.types';
import { ILocationDock } from '../../../../../models/location';
import { DockMountTypeEnum } from '../dock-mount-type-dropdown/dock-mount-type-dropdown.types';

export const handleOnAdd = (
  state: IAddDockState,
  setState: (state: IAddDockState) => void,
  onAddDock: (dock: ILocationDock) => void,
) => {
  if (onAddDock) {
    const newDock: ILocationDock = {
      number: state.dockNumber,
      dockCode: state.dockCode,
      positionId: state.snapInPosition.value,
      cableType: state.cableType,
      name: state.name,
      isFlipped: state.mountType === DockMountTypeEnum.Upwards,
    };
    onAddDock(newDock);
    setState({
      ...state,
      dockNumber: '',
      dockCode: '',
      name: '',
      snapInPosition: { value: '', label: '' },
      cableType: null,
      mountType: null,
    });
  }
};

export const isDockIdAvailable = (dockId, docks) =>
  !Object.values(docks)
    // @ts-ignore
    .flat()
    .some(({ number }) => number === dockId);

export const isDockNumberValid = (state, docks) => {
  // e.g. 213-56-8, 123.4, abc_1
  const validDockNumberRegExp = /^([a-zA-Z0-9]|([a-zA-Z0-9]*[_\-.][a-zA-Z0-9]))+$/i;
  const { dockNumber } = state;

  return (
    dockNumber &&
    validDockNumberRegExp.test(dockNumber) &&
    dockNumber.length <= 30 &&
    isDockIdAvailable(dockNumber, docks)
  );
};

export const isCeilingPosition = (position) => position.toLowerCase().includes('ceiling');

export default {
  isDockIdAvailable,
  handleOnAdd,
  isDockNumberValid,
};
