export enum DockMountTypeEnum {
  Downwards = 'downwards',
  Upwards = 'upwards',
}

export interface IToggleElement {
  mountType: DockMountTypeEnum;
  inlineMode?: boolean;
  disabled?: boolean;
}

export interface IContentElement {
  mountType: DockMountTypeEnum;
  onChange: (mountType: DockMountTypeEnum) => void;
  setShowDropdown: (dropdownStatus: boolean) => void;
}

export interface IMountTypeDropdown {
  mountType: DockMountTypeEnum;
  onChange: (mountType: DockMountTypeEnum) => void;
  inlineMode?: boolean;
  disabled?: boolean;
}
