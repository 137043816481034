import * as React from 'react';
import {
  Dropdown,
  ButtonType,
  ArrowDownIcon,
  variants,
  SnapInsGroupsEnums,
} from '@streda/web_components';
import styled from 'styled-components';
import {
  DockCableTypeEnum,
  ICableTypeDropdown,
  IContentElement,
  IToggleElement,
} from './cable-type-dropdown.types';
import {
  OptionButton,
  DropdownWrapper,
  Placeholder,
  ToggleContainer,
  StyledButton,
} from './cable-type-dropdown.components';

const DockCableTranslations = {
  [DockCableTypeEnum.Electric]: 'E-only',
  [DockCableTypeEnum.Hybrid]: 'Hybrid',
  [DockCableTypeEnum.Dockless]: 'Dockless',
  [DockCableTypeEnum.VirtualDock]: 'Virtual dock',
};

const CableOptionButton = styled(StyledButton)`
  width: 100%;
  margin-bottom: 8px;
`;

const Toggle: React.FC<IToggleElement> = ({
  cableType,
  inlineMode,
  compatibilityWarning,
  disabled,
}) => {
  return (
    <ToggleContainer inlineMode={inlineMode}>
      {inlineMode ? (
        <StyledButton
          rounded
          text={DockCableTranslations[cableType] || ''}
          type={ButtonType.BUTTON}
          colorType={variants.WHITE}
          compatibilityWarning={compatibilityWarning}
          disabled={disabled}
        />
      ) : (
        DockCableTranslations[cableType] || <Placeholder>Cable type</Placeholder>
      )}

      {!inlineMode && <ArrowDownIcon />}
    </ToggleContainer>
  );
};

const Content: React.FC<IContentElement> = ({
  cableType,
  onCableChange,
  setShowDropdown,
  selectedDock,
}) => {
  const onChange = (cableType: DockCableTypeEnum) => {
    onCableChange(cableType);
    setShowDropdown(false);
  };

  const getCableTypeOptions = () => {
    const dockType = selectedDock.charAt(2);
    const cableTypes = [];

    if (dockType === SnapInsGroupsEnums['0']) {
      cableTypes.push(DockCableTypeEnum.Dockless);
      // ISO002-3265
    } else if (selectedDock === 'SN2-E') {
      cableTypes.push(DockCableTypeEnum.VirtualDock);
    } else {
      cableTypes.push(DockCableTypeEnum.Electric, DockCableTypeEnum.Hybrid);
    }
    return (
      <>
        {cableTypes.map(dockCableType => (
          <CableOptionButton
            rounded
            text={DockCableTranslations[dockCableType]}
            type={ButtonType.BUTTON}
            colorType={cableType === dockCableType ? variants.PRIMARY : variants.WHITE}
            onClick={() => onChange(dockCableType)}
          />
        ))}
      </>
    );
  };

  return <div>{getCableTypeOptions()}</div>;
};

const CableTypeDropdown: React.FC<ICableTypeDropdown> = ({
  cableType,
  onCableChange,
  inlineMode,
  compatibilityWarning,
  disabled,
  selectedDock,
}) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  return (
    <DropdownWrapper>
      <Dropdown
        disabled={disabled}
        onToggle={() => {
          setShowDropdown(!showDropdown);
        }}
        show={showDropdown}
        toggleElement={
          <Toggle
            cableType={cableType}
            inlineMode={inlineMode}
            compatibilityWarning={compatibilityWarning}
            disabled={disabled}
          />
        }
        contentElement={
          <Content
            cableType={cableType}
            selectedDock={selectedDock}
            onCableChange={onCableChange}
            setShowDropdown={setShowDropdown}
          />
        }
      />
    </DropdownWrapper>
  );
};

export default CableTypeDropdown;
