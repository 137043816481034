import * as React from 'react';
import getClassNameForStatus from './status-indicator.logic';
import { IStatusIndicatorProps } from './status-indicator.types';
import { Container } from './status-indicator.components';

const StatusIndicator: React.FC<IStatusIndicatorProps> = (props: IStatusIndicatorProps) => {
  const { status, className, infoLvl } = props;
  return (
    <Container className={className}>
      <div className={getClassNameForStatus(infoLvl)}>{status}</div>
    </Container>
  );
};

export default StatusIndicator;
