export enum StatusIndicatorInfoLvlEnum {
  green,
  red,
  fulfilledRed,
  gray,
}

export interface IStatusIndicatorProps {
  status: string;
  className?: string;
  infoLvl?: StatusIndicatorInfoLvlEnum;
}
