import * as React from 'react';
import { Collapsible, DropArea, getRoomIconById } from '@streda/web_components';
import _ = require('lodash');
import styled from 'styled-components';
import { IDocksListProps } from './docks-list.types';
import AddDock from './add-dock/add-dock';
import {
  handleOnAddDock,
  handleOnDockDelete,
  handleOnDockChange,
  onDockNumberChange,
  onDockOrderChange,
} from './docks-list.logic';
import DeleteListElementModal from './delete-list-element-modal/delete-list-element-modal';
import { isDockNumberValid } from './add-dock/add-dock.logic';
import Dock from './dock';

const hideDockDeleteModal = setDeleteDockModalState => {
  setDeleteDockModalState({
    visible: false,
    dockToRemove: null,
    executing: false,
  });
};

const move = (array, fromIndex, toIndex) => {
  const element = array[fromIndex];
  const newArray = [...array];
  newArray.splice(fromIndex, 1); // Remove element from original position
  newArray.splice(toIndex, 0, element); // Insert element at the new position
  return newArray;
};

const RoomTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const DocksList: React.FC<IDocksListProps> = (props: IDocksListProps) => {
  const {
    className,
    docks,
    room,
    addMode,
    onChange,
    snapInTypes,
    notLegacySnapInTypes,
    locationId,
    snapInPositions,
  } = props;
  const [deleteDockModalState, setDeleteDockModalState] = React.useState({
    visible: false,
    dockToRemove: null,
    executing: false,
  });
  const roomDocks = docks[room.id];

  const onDeleteMemorized = React.useCallback(
    dock => {
      if (locationId) {
        setDeleteDockModalState({
          visible: true,
          dockToRemove: dock,
          executing: false,
        });
      } else {
        handleOnDockDelete(dock, roomDocks, locationId, onChange);
      }
    },
    [roomDocks],
  );
  const onChangeMemorized = React.useCallback(
    newDock => handleOnDockChange(newDock, roomDocks, locationId, onChange),
    [roomDocks],
  );
  const onDockNumberChangeMemorized = React.useCallback(
    (oldDockNumber, newDockNumber) => {
      const isDockValid = isDockNumberValid({ dockNumber: newDockNumber }, docks);

      if (!isDockValid) {
        return Promise.reject(new Error());
      }

      return onDockNumberChange(oldDockNumber, newDockNumber, locationId, roomDocks, onChange);
    },
    [roomDocks],
  );
  const onDockOrderChangeMemorized = React.useCallback(
    (toIndex, dockIndex) => onDockOrderChange(locationId, room.id, dockIndex, toIndex),
    [roomDocks],
  );

  return (
    <div className={className ?? ''}>
      <div className="text-medium">
        <Collapsible
          header={
            <div>
              <RoomTitle>
                {getRoomIconById(room.typeId)}
                {room.name} ( {roomDocks.length ? `${roomDocks.length}` : 0} )
              </RoomTitle>
            </div>
          }
          initiallyOpen={false}
        >
          <div className="m-t-16" />
          {addMode ? (
            <div>
              <AddDock
                snapInTypes={notLegacySnapInTypes}
                snapInPositions={snapInPositions}
                className="w-100p m-t-16"
                roomId={room.id}
                docks={docks}
                onAdd={dock => handleOnAddDock(dock, roomDocks, locationId, room.id, onChange)}
                editMode={!_.isNull(locationId)}
              />
            </div>
          ) : null}
          <div className="docks-list-table-container">
            <DropArea onDrop={() => {}}>
              <table className="text-medium docks-list-table w-100p m-t-16">
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '38%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '10%' }} />
                <thead>
                  <tr className="docks-text-header color-d3">
                    <th>Cable type</th>
                    <th>Mount type</th>
                    <th>Dock ID</th>
                    <th>Snap-in type & profile</th>
                    <th>Position</th>
                  </tr>
                </thead>
                <tbody>
                  {roomDocks.map((dock, dockIndex) => {
                    return (
                      <Dock
                        onDelete={onDeleteMemorized}
                        snapInTypes={snapInTypes}
                        notLegacySnapInTypes={notLegacySnapInTypes}
                        dock={dock}
                        onChange={onChangeMemorized}
                        addMode={addMode}
                        snapInPositions={snapInPositions}
                        onDockNumberChange={onDockNumberChangeMemorized}
                        onDockOrderChange={(fromIndex, toIndex) =>
                          onDockOrderChangeMemorized(toIndex, fromIndex)
                        }
                        pending={
                          deleteDockModalState.dockToRemove?.number === dock.number &&
                          deleteDockModalState.executing
                        }
                        dockIndex={dockIndex}
                      />
                    );
                  })}
                </tbody>
              </table>
            </DropArea>
          </div>
        </Collapsible>
      </div>
      <DeleteListElementModal
        show={deleteDockModalState.visible}
        onHide={() => {
          hideDockDeleteModal(setDeleteDockModalState);
        }}
        onDelete={() => {
          setDeleteDockModalState({
            ...deleteDockModalState,
            visible: false,
            executing: true,
          });
          handleOnDockDelete(
            deleteDockModalState.dockToRemove,
            roomDocks,
            locationId,
            onChange,
          ).finally(() => hideDockDeleteModal(setDeleteDockModalState));
        }}
      />
    </div>
  );
};

export default DocksList;
