import * as React from 'react';
import {
  AccountRoles,
  ArrowRightIcon,
  hasUserRole,
  IRemoteTableColumn,
  SortDirectionEnums,
  SystemUserDto,
  UsersIcon,
} from '@streda/web_components';

export interface IUsersPageProps {}

export enum SystemUserSortKey {
  NAME = 'name',
  SURNAME = 'surname',
  EMAIL = 'email',
}

export const userRoles = [
  { value: 'administrator', label: 'Administrator' },
  { value: 'installer', label: 'Installer' },
];

export const getUserColumns = (usersLenght: number = 0) => {
  const columns = [
    { key: 'id', name: '', hide: true },
    {
      key: 'icon',
      name: '',
      custom: true,
      customComponent: () => <UsersIcon />,
      disableSort: true,
      headerStyle: { width: '48px' },
      rowStyle: { paddingTop: '16px', paddingBottom: '16px', paddingLeft: '11px' },
    },
    {
      key: 'name',
      name: 'Full name',
      custom: true,
      customComponent: (row: SystemUserDto) => {
        return (
          <>
            {row.name} {row.surname}
          </>
        );
      },
      disableSort: usersLenght <= 1,
      rowStyle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      sortDirection: SortDirectionEnums.ASCENDING,
    },
    {
      key: 'email',
      name: 'Email',
      disableSort: true,
      custom: true,
      customComponent: (row: SystemUserDto) => {
        return (
          <div title={row.email} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.email}
          </div>
        );
      },
    },
    {
      key: 'role',
      name: 'Role',
      disableSort: true,
    },
    {
      disableSort: true,
      key: '',
      name: '',
      customComponent: () => <ArrowRightIcon />,
      headerStyle: { width: '40px', marginRight: '16px' },
      rowStyle: { overflow: 'unset' },
    },
  ] as IRemoteTableColumn[];

  if (hasUserRole(AccountRoles.SUPER_ADMINISTRATOR)) {
    columns.splice(3, 0, {
      key: 'companyName',
      name: 'Company',
      disableSort: usersLenght <= 1,
      rowStyle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    });
  }

  return columns;
};
