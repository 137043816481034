import * as React from 'react';
import {
  Button,
  ButtonType,
  hideLoader,
  LoaderMode,
  ModalLoader,
  RemoteTable,
  showLoader,
  SortDirectionEnums,
  TabSwitch,
  UsersIcon,
} from '@streda/web_components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getUserColumns, IUsersPageProps, SystemUserSortKey } from './users-page.types';
import useInternalUsers from './users-page.logic';
import { getCompanies } from '../../companies/companies-list/companies-list.logic';

export const TabSpacer = styled.div<{ height: number }>`
  width: 100%;
  height ${({ height }) => height}px;
`;

const InternalUsersPage = styled.div`
  padding-top: 95px;
  overflow: hidden;
  .users-table {
    height: calc(100vh - 300px);
    bottom: 0;
    background-color: white;
    margin-top: 33px;
    position: relative;
  }
`;

export const handleDataLoad = (isLoading: boolean) => {
  if (isLoading) {
    showLoader(<ModalLoader />, {
      mode: LoaderMode.COVER,
      loaderContainerSelector: '.users-table',
      zIndex: 0,
    });
  } else {
    hideLoader();
  }
};

const UsersPage: React.FC<IUsersPageProps> = () => {
  const [page, setPage] = React.useState(1);
  const [sortKey, setSortKey] = React.useState<SystemUserSortKey>(SystemUserSortKey.NAME);
  const [descending, setDescending] = React.useState<boolean>(false);
  const [accountEnabled, setAccountEnabled] = React.useState<boolean>(true);
  const { systemUsers, totalUsers, loading } = useInternalUsers(
    page,
    20,
    sortKey,
    descending,
    accountEnabled,
  );
  const history = useHistory();

  React.useEffect(() => {
    handleDataLoad(loading);
  }, [loading, sortKey, descending]);

  return (
    <InternalUsersPage className="page-content">
      <div className="d-flex bd-highlight">
        <div className="flex-grow-1 bd-highlight">
          <div className="h1-medium text-brand-highlight">Users</div>
        </div>
        <div className="bd-highlight">
          <Button
            type={ButtonType.BUTTON}
            text="Add new user"
            onClick={() => history.push(`/users/add`)}
          />
        </div>
      </div>
      <TabSpacer height={72} />

      <TabSwitch
        className="status-switch"
        options={[
          { key: 'active', title: 'Active' },
          { key: 'inactive', title: 'Inactive' },
        ]}
        onChange={e => {
          setAccountEnabled(e.key === 'active');
        }}
      />
      <RemoteTable
        tableTestId="users-table"
        className="users-table"
        onSortChange={e => {
          setDescending(e.sortDirection === SortDirectionEnums.DESCENDING);
          setSortKey(e.key as SystemUserSortKey);
        }}
        columns={getUserColumns(totalUsers)}
        onRowClick={row => history.push(`/users/${row.id}`)}
        data={systemUsers}
        onLoadMore={() =>
          setPage(prevState => {
            return prevState + 1;
          })
        }
        showMoreButton={systemUsers.length < totalUsers}
        emptyTableComponent={
          <div className="empty-table-content">
            <UsersIcon className="empty-list-indicator-icon" height="18vh" width="18vh" />
            <p className="empty-table-primary-label">No users</p>
            <p className="empty-table-secondary-label">
              All users will be displayed here. <br />
              Refresh tabs to browse users or <b>Add new user</b>
            </p>
          </div>
        }
      />
    </InternalUsersPage>
  );
};

export default UsersPage;
