export enum ScbStatusesEnum {
  Unknown = 'Unknown',
  AllConnected = 'AllConnected',
  PartiallyConnected = 'PartiallyConnected',
  AllDisconnected = 'AllDisconnected',
  Error = 'Error',
}

type IBcbStatusesDict = {
  [key in ScbStatusesEnum]: string;
};

export const bcbStatusesDict: IBcbStatusesDict = {
  [ScbStatusesEnum.Unknown]: 'Unknown',
  [ScbStatusesEnum.AllConnected]: 'All active',
  [ScbStatusesEnum.PartiallyConnected]: '1 Inactive',
  [ScbStatusesEnum.AllDisconnected]: 'All Inactive',
  [ScbStatusesEnum.Error]: 'Error',
};

export const getNetworkStatus = (status: string) => {
  return bcbStatusesDict[status] ?? bcbStatusesDict.Unknown;
};
