import styled from 'styled-components';
import { Button } from '@streda/web_components';

export const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.font1}60;
`;

export const ToggleContainer = styled.div<{
  inlineMode: boolean;
}>`
  display: flex;
  padding: ${({ inlineMode }) => (inlineMode ? 0 : '8px')};
  border: 0.75px solid
    ${({ theme, inlineMode }) => (inlineMode ? 'transparent' : theme.colors.primary)};
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
  height: 36px;
  text-align: left;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButton = styled(Button)<{ compatibilityWarning?: boolean }>`
  border-color: ${({ theme, compatibilityWarning }) =>
    compatibilityWarning ? theme.colors.alert : undefined} !important;
  color: ${({ theme, compatibilityWarning }) =>
    compatibilityWarning ? theme.colors.alert : undefined} !important;
  min-width: unset;
`;

export const OptionButton = styled(StyledButton)`
  width: 100%;
  margin-bottom: 8px;
`;

export const DropdownWrapper = styled.div`
  margin-right: 8px;
`;
