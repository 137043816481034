import * as React from 'react';
import { Dropdown, ButtonType, ArrowDownIcon, variants } from '@streda/web_components';
import {
  DockMountTypeEnum,
  IContentElement,
  IMountTypeDropdown,
  IToggleElement,
} from './dock-mount-type-dropdown.types';
import { DropdownWrapper } from '../dock-dropdown/dock-dropdown.styled';
import {
  OptionButton,
  Placeholder,
  StyledButton,
  ToggleContainer,
} from '../cable-type-dropdown/cable-type-dropdown.components';

const DockMountTranslations = {
  [DockMountTypeEnum.Downwards]: 'Downwards',
  [DockMountTypeEnum.Upwards]: 'Upwards',
};

const Toggle: React.FC<IToggleElement> = ({ mountType, inlineMode, disabled }) => {
  return (
    <ToggleContainer inlineMode={inlineMode}>
      {inlineMode ? (
        <StyledButton
          rounded
          text={DockMountTranslations[mountType] || ''}
          type={ButtonType.BUTTON}
          colorType={variants.WHITE}
          disabled={disabled}
        />
      ) : (
        DockMountTranslations[mountType] || <Placeholder>Dock mount</Placeholder>
      )}

      {!inlineMode && <ArrowDownIcon />}
    </ToggleContainer>
  );
};

const Content: React.FC<IContentElement> = ({
  mountType,
  onChange: onMountTypeChange,
  setShowDropdown,
}) => {
  const onChange = (mountType: DockMountTypeEnum) => {
    onMountTypeChange(mountType);
    setShowDropdown(false);
  };

  const getCableTypeOptions = () => {
    return (
      <>
        {Object.values(DockMountTypeEnum).map(type => (
          <OptionButton
            rounded
            text={DockMountTranslations[type]}
            type={ButtonType.BUTTON}
            colorType={mountType === type ? variants.PRIMARY : variants.WHITE}
            onClick={() => onChange(type)}
          />
        ))}
      </>
    );
  };

  return <div>{getCableTypeOptions()}</div>;
};

const DockMountTypeDropdown: React.FC<IMountTypeDropdown> = ({
  mountType,
  onChange,
  inlineMode,
  disabled,
}) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  return (
    <DropdownWrapper>
      <Dropdown
        disabled={disabled}
        onToggle={() => {
          setShowDropdown(!showDropdown);
        }}
        show={showDropdown}
        toggleElement={<Toggle mountType={mountType} inlineMode={inlineMode} disabled={disabled} />}
        contentElement={
          <Content mountType={mountType} onChange={onChange} setShowDropdown={setShowDropdown} />
        }
      />
    </DropdownWrapper>
  );
};

export default DockMountTypeDropdown;
